<template>
    <div class="processBox">
        <div class="processText" :style="'width:' + process + '%'">

        </div>
        <span class="processDes">{{ son }} / {{ father }}</span>
        <!-- <span class="processDes">2000 / 2000</span> -->
    </div>
</template>

<script>
    export default{
        name:"SimpleProcess",
        props:{
            son:{
                type:Number,
            },
            father:{
                type:Number
            }
        },
        computed:{
            process(){
                return this.son / this.father * 100
            }
        },
        data(){
            return {
            }
        },
        methods:{

        }
    }
</script>

<style lang="css" scoped>
    .processDes{
        color:var(--mainfont);
        font-size:1rem;
        position:absolute;
        left:0.5rem;
        font-weight:bold;
        z-index:2;
        top:50%;
        transform:translate(0,-50%);
        width:85%;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
    }
    .processText{
        background-color: var(--gdient2);
        height:100%;
        border-radius:1rem;
    }
    .processBox{
        width:100%;
        height:1.5rem;
        background:var(--background);
        border-radius:1rem;
        overflow:hidden;
        position:relative;
    }
</style>
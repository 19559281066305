<template>
    <div class="container">
        <div class="headTitle">
            <img :src="Left" style="transform:rotate(180deg)" @click="goBack" />
            <span v-text="title">
            </span>
        </div>
        <div class="content" v-html="content">
        </div>
    </div>
</template>

<script>

    import Left from "@/assets/cashwheel/left.png";

    export default {
        name:"Help",
        data(){
            return {
                Left,
                title:"FAQ",
                content:""
            }
        },
        methods:{
            goBack(){
                this.$router.go(-1);
            },
            init(){
                this.title = this.$route.query.name;
                this.content = this.$store.state.user.ajuda.find(item => item.noticeTitle == this.title).noticeContent;
            }
        },
        mounted(){
            this.init();
        }
    }

</script>

<style scoped>

    .content{
        width:100%;
        height:fit-content;
    }

    .headTitle span{
        color:var(--basic);
        font-size:2rem;
        font-weight:bold;
        margin-left:1rem;
    }

    .headTitle img {
        width:3rem;
    }

    .headTitle{
        display:flex;
        align-items: center;
    }

    .container{
        height:100%;
        overflow:hidden;
        width:100%;
        position:relative;
        box-sizing:border-box;
        padding:2rem;
    }
</style>
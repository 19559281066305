<template>
    <div class="downloadAPP">
        <div class="downloadHeader">
            <div class="downloadHeaderLeft">
                <img :src="logo" />
                <span>{{ $t("InstallModalTitle") }}</span>
            </div>
            <svg @click="close" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
            </svg>
        </div>
        <div class="downloadContent">
            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="256" height="256">
                <path d="M796.4 0H227.6c-62.8 0-113.8 50.9-113.8 113.8v796.4c0 62.8 50.9 113.8 113.8 113.8h568.9c62.8 0 113.8-50.9 113.8-113.8V113.8C910.2 50.9 859.3 0 796.4 0zM552.2 927c-10.7 10.7-25.1 16.7-40.2 16.7-31.4 0-56.9-25.5-56.9-56.9s25.5-56.9 56.9-56.9 56.9 25.5 56.9 56.9c0 15-6 29.5-16.7 40.2z m242-244.7c0 15.1-6 29.5-16.5 40.2-10.6 10.7-24.9 16.6-39.9 16.6H286.2c-15 0-29.3-6-39.9-16.6-10.6-10.7-16.5-25.1-16.5-40.2V171c0-31.4 25.3-56.8 56.4-56.8h451.6c15 0 29.3 6 39.9 16.6 10.6 10.7 16.5 25.1 16.5 40.2v511.3z" fill="#666666"></path>
                <path d="M603.8 422.4l-56.5 56.5V272.5c0-19.7-15.9-35.6-35.6-35.6-19.7 0-35.6 15.9-35.6 35.6v206.4l-56.4-56.5c-13.9-13.9-36.4-13.9-50.3 0-13.9 13.9-13.9 36.4 0 50.3l117.1 117.2c6.7 6.7 15.7 10.4 25.1 10.4s18.5-3.8 25.1-10.4l117.2-117.2c13.9-13.9 13.9-36.4 0-50.3s-36.2-13.8-50.1 0z" fill="#666666"></path>
            </svg>
            <span class="downloadTips">{{ $t("InstallModalTips") }}</span>
            <div class="downloadAPPOption">
                <div class="downloadAPPOptionItem buttonTransition" @click="close">
                    {{ $t("Cancel") }}
                </div>
                <div class="downloadAPPOptionItemActive buttonTransition" @click="download">
                    {{ $t("Confirm") }}
                    <div class="addToScreenPover">
                        <span>16</span>
                        <img :src="BlackCoin"/>
                    </div>
                </div>
            </div>
        </div>
            
    </div>
</template>

<script lang="js">
    
    import { mapState } from 'vuex';
    import BlackCoin from "@/assets/img/blackcoin.png";

    export default {
        name:"DownloadAPP",
        data(){
            return {
                BlackCoin
            }
        },
        computed:{
            HeaderPic() {
                return getAvatarImage(this.$store.state.user.avatar);
            },
            ...mapState({
                logo:state => state.user.logo,
            })
        },
        props:{
            visible:{
                type:Boolean,
            }
        },
        methods:{
            close(){
                this.$emit("update:visible",false);
            },
            download(){
                const link = document.createElement('a');
                link.href = '/app.apk';  // 指向 public 文件夹中的 app.apk
                link.download = 'ckgames.apk';  // 设置下载的文件名
                link.click();  // 模拟点击下载
                this.$emit("update:visible",false);
            }
        }
    }

</script>

<style lang="css" scoped>

    @keyframes scaleAnimation {
        0% {
            transform: scale(1); /* 开始时正常大小 */
        }
        100% {
            transform: scale(1.1); /* 结束时放大到1.2倍 */
        }
    }

    .addToScreenPover > img {
        height: 2rem;
        width: 2rem;
        margin-left: 1rem;
    }

    .addToScreenPover {
        animation: scaleAnimation 1s infinite alternate;
        position: absolute;
        z-index: 30;
        width: 5rem;
        height: 2rem;
        background: linear-gradient(to bottom, var(--error), var(--error));
        border-radius: 2rem;
        color: #fff;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: .5rem;
        padding-left: 1.2rem;
        right: -1rem;
        top: -1.5rem;
    }

    .downloadAPPOption{
        display:flex;
        justify-content: space-between;
        width:100%;
        margin-top:3rem;
    }

    .downloadAPPOptionItemActive{
        background:var(--success);
        color:#fff;
        box-sizing:border-box;
        padding:1rem 1.5rem;
        font-weight:bold;
        font-size:1.5rem;
        border-radius:1rem;
        position:relative;
    }

    .downloadAPPOptionItem{
        background:var(--error);
        color:#fff;
        box-sizing:border-box;
        padding:1rem 1.5rem;
        font-weight:bold;
        font-size:1.5rem;
        border-radius:1rem;
    }

    .downloadContent{
        display:flex;
        flex-direction: column;
        align-items: center;
        width:80%;
    }

    .downloadTips{
        color:var(--basic);
        font-size:1.4rem;
        margin-top:2rem;
        font-weight:bold;

    }

    .downloadContent > svg {
        width:12rem;
        height:12rem;
        margin-top:2rem;
    }

    .downloadHeaderLeft > img {
        height:4rem;
    }

    .downloadHeaderLeft > span {
        font-weight:bold;
        color:var(--basic);
        font-size:1.5rem;
        margin-left:0.3rem;
    }

    .downloadHeaderLeft{
        display:flex;
        align-items: center;
    }

    .downloadHeader{
        width:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .downloadHeader path {
        fill:var(--basic);
    }

    .downloadHeader > svg {
        height:2rem;
        width:2rem;
    }

    .downloadAPP{
        position:relative;
        margin:0 auto;
        width:90%;
        height:fit-content;
        /* background:#fff; */
        background:var(--primary);
        border-radius:1rem;
        box-sizing:border-box;
        padding:2rem;
        display:flex;
        align-items: center;
        flex-direction: column;
    }
    

</style>
<template>
    <div class="modalShadow" @click="closeModal" :style=" visible ? '' : 'display:none' ">
        <transition name="breathe">
            <div v-show="visible" class="modalBox" :style="getStyle()">
                <div class="selectBox" @click.stop="defaultF">
                    <div class="selectBoxTitle">
                        <span class="selectBoxTitleCancel" @click="close">{{ $t("Cancel") }}</span>
                        <span class="selectBoxTitleTitle">{{ $t("Tip") }}</span>
                        <span class="selectBoxTitleConfirm" @click="confirm">{{ $t("Confirm") }}</span>
                    </div>
                    <div class="selecMonthBox">
                        <div :class="active == index ? 'selectMonthItem active' : 'selectMonthItem'" v-for="(item,index) in 12" @click="toSelect(monthLabelRender(item), index)" :key="item">
                            <span v-text="monthLabelRender(item)">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name:"MonthSelect",
        props:{
            value:{
                type:String,
                default:""
            },
            visible:{
                type:Boolean,
                default:false,
            },
            beforeClose:{
                type:Function,
                default:()=>{}
            },
            beforeConfirm:{
                type:Function,
                default:() => {}
            },
            widths:{
                type:Number,
                default:100
            },
        },
        data(){
            return {
                model:"",
                active:-1,
                width:0
            }
        },
        mounted(){
            const resize = () => {
                this.$nextTick(() => {
                    if(document.body.style.maxWidth.trim()){
                        this.width = parseInt(document.body.style.maxWidth);
                    }else{
                        this.width = window.innerWidth;
                    }
                })
            }
            resize();
            window.addEventListener('resize', resize);
        },
        methods:{
            defaultF(){
            },
            monthLabelRender(item){
                switch(item){
                    case 1:
                        return this.$t("January")
                    case 2:
                        return this.$t("February")
                    case 3:
                        return this.$t("March")
                    case 4:
                        return this.$t("April")
                    case 5:
                        return this.$t("May")
                    case 6:
                        return this.$t("June")
                    case 7:
                        return this.$t("July")
                    case 8:
                        return this.$t("August")
                    case 9:
                        return this.$t("September")
                    case 10:
                        return this.$t("October")
                    case 11:
                        return this.$t("November")
                    case 12:
                        return this.$t("December")
                }
            },
            toSelect(value,index){
                this.model = value;
                this.active = index;
                this.confirm();
            },
            closeModal(){
                this.beforeClose();
                this.$emit("update:visible",false)
            },
            confirm(){
                this.beforeClose();
                this.$emit("update:value",this.model)
                this.$emit("update:visible",false)
            },
            close(){
                this.beforeClose();
                this.$emit("update:visible",false)
            },
            getStyle(){
                let result = "";

                // if(this.gradient){
                //     result += 'background: linear-gradient(to bottom, #0b2b62, var(--sub8black));'
                // }else if (!this.hasBg){
                //     result += 'background:none;';
                // }
                // if(!this.padding){
                //     result += 'padding:0 !important;'
                // }
                
                result += "max-width:" + this.width + 'px;width:' + this.widths + '%'

                return result;
            }
        }
    }
</script>

<style scoped>

    .selectMonthItem.active{
        color:var(--gdient2);
        background:rgba(255,255,255,.05);
    }

    .selectMonthItem{
        height:5rem;
        width:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.05rem solid rgba(255, 255, 255, 0.1);
        color:var(--subfont);
        font-size:1.5rem;
        font-weight:bold;
    }

    .selecMonthBox{
        height:40rem;
        width:100%;
        box-sizing:border-box;
        padding:0 2rem;
        overflow-x:hidden;
        overflow-y:scroll;
        scrollbar-width:none;
    }

    .selectBoxTitleConfirm{
        color:var(--gdient2);
        font-weight:bold;
        font-size:1.5rem;
    }

    .selectBoxTitleTitle{
        color:var(--basic);
        font-weight:bold;
        font-size:1.5rem;
    }

    .selectBoxTitleCancel{
        color:var(--subfont);
        font-weight:bold;
        font-size:1.5rem;
    }

    .selectBoxTitle{
        height:6rem;
        display:flex;
        justify-content: space-between;
        align-items: center;
        box-sizing:border-box;
        padding:0 2rem;
        border-bottom: 0.05rem solid rgba(255, 255, 255, 0.1)
    }

    .selectBox{
        width:100%;
        position:absolute;
        z-index:1;
        bottom:0;
        left:0;
        background:var(--primary);
        overflow:hidden;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
    }

    .selectBox path {
        fill:var(--subfont);
    }

    .modalBox{
        max-width:557px;
        box-sizing:border-box;
        padding:3rem 3rem;
        border-radius:1rem;
        height:fit-content;
        width:100%;
        position:relative;
        height:100%;
    }

    .modalShadow{
        height:100%;
        width:100vw;
        position:fixed;
        z-index:999999;
        background-color:rgba(0,0,0,.8);
        display:flex;
        justify-content:center;
        align-items: center;
        top:0;
        left:0;
    }
</style>
<template>
    <div class="box">
        <div class="header">
            <div class="navList">
                <div @click="changeType(index)" :class="index===navSelected ? 'navItem active' : 'navItem'" v-for="(item,index) in navList" :key="index" v-text="item">
                </div>
            </div>
            <svg @click="toBack" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
            </svg>
        </div>
        <template v-if="navList[navSelected]===$t('Login')">
            <div class="form">
                <div class="formClassList">
                    <div :class="classItemIndex == 1 ? 'formClassItem active buttonTransition' : 'formClassItem buttonTransition'" @click="changeClass(1)">
                        {{ $t("Phone") }}
                    </div>
                    <div :class="classItemIndex == 2 ? 'formClassItem active buttonTransition' : 'formClassItem buttonTransition'" @click="changeClass(2)">
                        {{ $t("Email") }}
                    </div>
                    <div :class="classItemIndex == 0 ? 'formClassItem active buttonTransition' : 'formClassItem buttonTransition'" @click="changeClass(0)">
                        {{ $t("User") }}
                    </div>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;width:100%;position:relative;z-index:1;">
                        <div class="selectBox" v-if="classItemIndex != 1">
                            <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path d="M512 298.666667m-128 0a128 128 0 1 0 256 0 128 128 0 1 0-256 0Z" fill="#495669"></path>
                                <path d="M640 426.666667h-17.066667c-29.866667 25.6-68.266667 42.666667-110.933333 42.666666s-81.066667-17.066667-110.933333-42.666666H384c-46.933333 0-68.266667 42.666667-85.333333 85.333333-25.6 68.266667-68.266667 192-42.666667 256 17.066667 42.666667 38.4 85.333333 85.333333 85.333333h341.333334c46.933333 0 68.266667-42.666667 85.333333-85.333333 21.333333-59.733333-17.066667-192-42.666667-256-17.066667-42.666667-38.4-85.333333-85.333333-85.333333z" fill="#495669"></path>
                            </svg>
                        </div>
                        <el-dropdown @visible-change="changePhoneConutry" trigger="click" placement="bottom-start" v-else>
                            <div class="selectBox">
                                <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                    <path d="M512 298.666667m-128 0a128 128 0 1 0 256 0 128 128 0 1 0-256 0Z" fill="#495669"></path>
                                    <path d="M640 426.666667h-17.066667c-29.866667 25.6-68.266667 42.666667-110.933333 42.666666s-81.066667-17.066667-110.933333-42.666666H384c-46.933333 0-68.266667 42.666667-85.333333 85.333333-25.6 68.266667-68.266667 192-42.666667 256 17.066667 42.666667 38.4 85.333333 85.333333 85.333333h341.333334c46.933333 0 68.266667-42.666667 85.333333-85.333333 21.333333-59.733333-17.066667-192-42.666667-256-17.066667-42.666667-38.4-85.333333-85.333333-85.333333z" fill="#495669"></path>
                                </svg>
                                <span>+55</span>
                                <svg style="margin-left:.5rem" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                    <path d="M281.6 409.6l221.866667 268.8c4.266667 4.266667 12.8 4.266667 17.066666 0l221.866667-268.8c4.266667-8.533333 0-17.066667-8.533333-17.066667H290.133333c-12.8 0-17.066667 8.533333-8.533333 17.066667z" fill="#495669"></path>
                                </svg>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <div class="countryItem">
                                    <span>Philippines</span>
                                    <span>+55</span>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <input @focus="toFocusLoginPhone" @blur="toBlurLoginPhone" v-model="loginPhone" type="text" :placeholder="classItemIndex == 0 ? 'User Name' : classItemIndex == 1 ? 'Phone Number' : 'Email Address'"/>
                    </div>
                    <svg style="position:relative;z-index:1;" @click="loginPhone = ''" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
                    </svg> 
                    <div class="InputBoxInner" :style="(loginPhoneFocus && showLoginPhoneTips) ? 'border:0.1rem solid red' : (loginPhoneFocus && (!showLoginPhoneTips)) ? 'border:0.1rem solid green' : ''">
                    </div>
                </div>
                <div v-if="showLoginPhoneTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                    <img :src="WarningIcon" style="width:1.3rem;" />
                    <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ loginPhone.length > 0 ? classItemIndex == 1 ? $t("PhoneRegTips") : classItemIndex == 0 ? $t("UserRegTips") : $t("EmailRegTips") : $t("AccountRegTips") }}</span>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;width:100%;position:relative;z-index:1;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z" fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input @focus="toFocusLoginPassword" @blur="toBlurLoginPassword" v-model="loginPassword" :type="loginPasswordMode" placeholder="Password" @keyup.enter="Login"/>
                    </div>
                    <template v-if="loginPasswordMode == 'password'">
                        <svg style="position:relative;z-index:1;" @click="loginPasswordMode = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="loginPasswordMode == 'text'">
                        <svg style="position:relative;z-index:1;" @click="loginPasswordMode = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                        </svg>
                    </template>
                    <div class="InputBoxInner" :style="(loginPasswordFocus && showLoginPwdTips) ? 'border:0.1rem solid red' : (loginPasswordFocus && (!showLoginPwdTips)) ? 'border:0.1rem solid green' : ''">
                    </div>
                </div>
                <div v-if="showLoginPwdTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                    <img :src="WarningIcon" style="width:1.3rem;" />
                    <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ loginPassword.length > 0 ? loginPassword == loginPhone ? $t("AccountCantSameForPassword") : $t("PasswordInputRegTips") : $t("PasswordRegTips") }}</span>
                </div>

                <div class="optionBox">
                    <div class="optionLeft">
                        <Checkbox :model.sync="rememberMe"></Checkbox>
                        <span>{{ $t("RememberMe") }}</span>
                    </div>
                    <span @click="toShowForget">{{ $t("ForgetPassword") }}?</span>
                </div>
                <div class="LoginButton buttonTransition" @click="Login" :style="!logCanPush ? 'background:rgba(255, 255, 255, 0.3)' : ''">
                    {{ $t("Login") }}
                    <span style="font-size:1rem;margin-left:0.3rem;font-weight:bold;" v-loading="isPush"></span>
                </div>
                <div class="SignUpButton buttonTransition" @click="navSelected=1">
                    {{ $t("SignUp") }}
                </div>
                <div class="formDes">
                    By accessing the site.I confirm that I am 18 years old and I have read the
                    <span class="formDesButton" @click="toAgreement">Terms of Service</span>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="fixedForm" v-show="cpfEnable && basicVisible">
                <div class="fixedFormItem">
                    <div class="fixedFormItemLabel">
                        {{ $t("CPF") }}
                        <span class="fixedFormItemRequired">*</span>
                    </div>
                    <div class="InputBox">
                        <div style="display:flex;align-items: center;width:100%;position:relative;z-index:1;">
                            <div class="selectBox">
                                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83333 2.8335C1.82081 2.8335 1 3.65431 1 4.66683V19.3335C1 20.346 1.82081 21.1668 2.83333 21.1668H21.1667C22.1792 21.1668 23 20.346 23 19.3335V4.66683C23 3.65431 22.1792 2.8335 21.1667 2.8335H2.83333ZM4.77887 9.71687C4.77887 11.072 5.61904 12.2309 6.8069 12.7011C4.88194 13.1629 3.3865 14.7289 3.02782 16.6918C2.93683 17.1898 3.35594 17.6039 3.86221 17.6039H12.1122C12.6185 17.6039 13.0376 17.1898 12.9466 16.6918C12.5879 14.7289 11.0925 13.1629 9.16751 12.7011C10.3554 12.2309 11.1955 11.072 11.1955 9.71687C11.1955 7.94496 9.75912 6.50854 7.9872 6.50854C6.21529 6.50854 4.77887 7.94496 4.77887 9.71687ZM19.9207 9.64957C20.427 9.64957 20.8374 10.06 20.8374 10.5662C20.8374 11.0725 20.427 11.4829 19.9207 11.4829H14.4207C13.9145 11.4829 13.5041 11.0725 13.5041 10.5662C13.5041 10.06 13.9145 9.64957 14.4207 9.64957H19.9207ZM20.8374 15.1496C20.8374 14.6433 20.427 14.2329 19.9207 14.2329H14.4207C13.9145 14.2329 13.5041 14.6433 13.5041 15.1496C13.5041 15.6558 13.9145 16.0662 14.4207 16.0662H19.9207C20.427 16.0662 20.8374 15.6558 20.8374 15.1496Z" fill="#039685"></path>
                                </svg>
                            </div>
                            <input @focus="toFocusCPF" @blur="toBlurCPF" v-model="idcard" :placeholder="$t('CPF')"/>
                        </div>
                        <!-- <div class="verifyButton buttonTransition">
                            {{ $t("Verify") }}
                        </div> -->
                        <div class="InputBoxInner" :style="cpfFocus && showCPFTips ? 'border:0.1rem solid red' : cpfFocus && !showCPFTips ? 'border:0.1rem solid green' : ''">
                        </div>
                    </div>
                    <div v-if="showCPFTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                        <img :src="WarningIcon" style="width:1.3rem;" />
                        <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ $t("CPFTips") }}</span>
                    </div>
                </div>
                <div class="fixedFormItem">
                    <div class="fixedFormItemLabel">
                        {{ $t("FirstNameAndLastName") }}
                        <span class="fixedFormItemRequired">*</span>
                    </div>
                    <div class="InputBox">
                        <div style="display:flex;align-items: center;width:100%;position:relative;z-index:1;">
                            <div class="selectBox">
                                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4995 11.5C14.2609 11.5 16.4995 9.26142 16.4995 6.5C16.4995 3.73858 14.2609 1.5 11.4995 1.5C8.73809 1.5 6.49951 3.73858 6.49951 6.5C6.49951 9.26142 8.73809 11.5 11.4995 11.5Z" fill="#039685"></path>
                                    <path d="M1.56073 20.8905C1.98347 17.0602 4.56953 13.8816 8.07019 12.6035C9.08375 13.1743 10.2538 13.4999 11.4999 13.4999C12.746 13.4999 13.916 13.1743 14.9296 12.6035C18.4303 13.8816 21.0163 17.0602 21.4391 20.8905C21.5064 21.5005 21.0024 21.9999 20.3888 21.9999H2.61101C1.99736 21.9999 1.49342 21.5005 1.56073 20.8905Z" fill="#039685"></path>
                                </svg>
                            </div>
                            <input @focus="toFocusRealName" @blur="toBlurRealName" v-model="realName" :placeholder="$t('FirstNameAndLastName')"/>
                        </div>
                        <div class="InputBoxInner" :style="realNameFocus && showRealNameTips ? 'border:0.1rem solid red' : realNameFocus && !showRealNameTips ? 'border:0.1rem solid green' : ''">
                        </div>
                    </div>
                    <div v-if="showRealNameTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                        <img :src="WarningIcon" style="width:1.3rem;" />
                        <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ $t("RealNameTips") }}</span>
                    </div>
                </div>
                <div class="fixedFormItem">
                    <div class="fixedFormItemLabel">
                        {{ $t("DateOfBirth") }}
                        <span class="fixedFormItemRequired">*</span>
                    </div>
                    <div class="FlexBox">
                        <SettingInput :value.sync="day" :placeholder="$t('Days')" type="disabledi" style="margin-right:1rem" :inputvalidate="DateValidateInput">
                        </SettingInput>
                        <SettingInput :value.sync="month" type="disabled" :placeholder="$t('Month')" style="margin-right:1rem" :click="showMonth">
                            <svg class="rightSvg" slot="right" width="11" height="40" viewBox="0 0 11 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1_342481)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.669908 10.9844C0.962801 10.6915 1.43768 10.6915 1.73057 10.9844L10.7462 20L1.73057 29.0156C1.43768 29.3085 0.962801 29.3085 0.669908 29.0156C0.377015 28.7227 0.377015 28.2478 0.669908 27.955L8.62486 20L0.669908 12.045C0.377015 11.7522 0.377015 11.2773 0.669908 10.9844Z" fill="#2FA33F"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_342481">
                                        <rect width="11" height="40" rx="5.5" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </SettingInput>
                        <SettingInput :value.sync="year" :placeholder="$t('Year')" type="disabledi" :inputvalidate="YearValidateInput">
                        </SettingInput>
                    </div>
                    <div v-if="showBirthdayTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                        <img :src="WarningIcon" style="width:1.3rem;" />
                        <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ $t("No18Age") }}</span>
                    </div>
                </div>
                <div class="fixedFormItem">
                    <div class="fixedFormItemLabel">
                        {{ $t("Gender") }}
                        <span class="fixedFormItemRequired">*</span>
                    </div>
                    <div class="InputBox" style="justify-content:space-around">
                        <Radio name="1" :label="$t('Male')" :control.sync="gender"></Radio>
                        <Radio name="2" :label="$t('Female')" :control.sync="gender"></Radio>
                    </div>
                </div>
                <div class="LoginButton buttonTransition" @click="basicRegister" :style="!basicCanPush ? 'background:rgba(255, 255, 255, 0.3)' : ''">
                    {{ $t("Continue") }}
                </div>
            </div>
            <div class="form">
                <div class="formClassList">
                    <div :class="classItemIndex == 1 ? 'formClassItem active buttonTransition' : 'formClassItem buttonTransition'" @click="changeClass(1)">
                        {{ $t("Phone") }}
                    </div>
                    <div :class="classItemIndex == 2 ? 'formClassItem active buttonTransition' : 'formClassItem buttonTransition'" @click="changeClass(2)">
                        {{ $t("Email") }}
                    </div>
                    <div :class="classItemIndex == 0 ? 'formClassItem active buttonTransition' : 'formClassItem buttonTransition'" @click="changeClass(0)">
                        {{ $t("User") }}
                    </div>
                </div>
                <div class="InputBox">

                    <div style="display:flex;align-items: center;width:100%;position:relative;z-index:1;">
                        <div class="selectBox" v-if="classItemIndex != 1">
                            <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path d="M512 298.666667m-128 0a128 128 0 1 0 256 0 128 128 0 1 0-256 0Z" fill="#495669"></path>
                                <path d="M640 426.666667h-17.066667c-29.866667 25.6-68.266667 42.666667-110.933333 42.666666s-81.066667-17.066667-110.933333-42.666666H384c-46.933333 0-68.266667 42.666667-85.333333 85.333333-25.6 68.266667-68.266667 192-42.666667 256 17.066667 42.666667 38.4 85.333333 85.333333 85.333333h341.333334c46.933333 0 68.266667-42.666667 85.333333-85.333333 21.333333-59.733333-17.066667-192-42.666667-256-17.066667-42.666667-38.4-85.333333-85.333333-85.333333z" fill="#495669"></path>
                            </svg>
                        </div>
                        <el-dropdown @visible-change="changePhoneConutry" trigger="click" placement="bottom-start" v-else>
                            <div class="selectBox">
                                <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                    <path d="M512 298.666667m-128 0a128 128 0 1 0 256 0 128 128 0 1 0-256 0Z" fill="#495669"></path>
                                    <path d="M640 426.666667h-17.066667c-29.866667 25.6-68.266667 42.666667-110.933333 42.666666s-81.066667-17.066667-110.933333-42.666666H384c-46.933333 0-68.266667 42.666667-85.333333 85.333333-25.6 68.266667-68.266667 192-42.666667 256 17.066667 42.666667 38.4 85.333333 85.333333 85.333333h341.333334c46.933333 0 68.266667-42.666667 85.333333-85.333333 21.333333-59.733333-17.066667-192-42.666667-256-17.066667-42.666667-38.4-85.333333-85.333333-85.333333z" fill="#495669"></path>
                                </svg>
                                <span>+55</span>
                                <svg style="margin-left:.5rem" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                    <path d="M281.6 409.6l221.866667 268.8c4.266667 4.266667 12.8 4.266667 17.066666 0l221.866667-268.8c4.266667-8.533333 0-17.066667-8.533333-17.066667H290.133333c-12.8 0-17.066667 8.533333-8.533333 17.066667z" fill="#495669"></path>
                                </svg>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <div class="countryItem">
                                    <span>Philippines</span>
                                    <span>+55</span>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <input @focus="toFocusUserName" @blur="toBlurUserName" v-model="regPhone" type="text" :placeholder="classItemIndex == 0 ? $t('UserName') : classItemIndex == 1 ? $t('PhoneNumber') : $t('EmailAddress')"/>
                    </div>
                    <svg @click="regPhone = ''" class="icon" style="position:relative;z-index:1;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
                    </svg>
                    <div class="InputBoxInner" :style="(regPhoneFocus && showUserNameTips) ? 'border:0.1rem solid red' : (regPhoneFocus && (!showUserNameTips)) ? 'border:0.1rem solid green' : ''">
                    </div>
                </div>
                <div v-if="showUserNameTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                    <img :src="WarningIcon" style="width:1.3rem;" />
                    <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ regPhone.length > 0 ? classItemIndex == 1 ? $t("PhoneRegTips") : classItemIndex == 0 ? $t("UserRegTips") : $t("EmailRegTips") : $t("AccountRegTips") }}</span>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;width:100%;position:relative;z-index:1;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z" fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input @focus="toFocusPwd" @blur="toBlurPwd" v-model="regPassword" :type="regPasswordMode" :placeholder="$t('Password')" @keyup.enter="createAccount"/>
                    </div>
                    <template v-if="regPasswordMode === 'password'">
                        <svg @click="regPasswordMode = 'text'" class="icon" style="position:relative;z-index:1;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="regPasswordMode === 'text'">
                        <svg @click="regPasswordMode = 'password'" class="icon" style="position:relative;z-index:1;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                        </svg>
                    </template>
                    <div class="InputBoxInner" :style="regPasswordFocus && showUserPwdTips ? 'border:0.1rem solid red' : regPasswordFocus && !showUserPwdTips ? 'border:0.1rem solid green' : ''">
                        
                    </div>
                </div>
                <div v-if="showUserPwdTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                    <img :src="WarningIcon" style="width:1.3rem;" />
                    <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ regPassword.length > 0 ? regPassword == regPhone ? $t("AccountCantSameForPassword") : $t("PasswordInputRegTips") : $t("PasswordRegTips") }}</span>
                </div>
                <div v-if="showPwdStrong" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                    <span style="color: #fff;font-weight:bold;font-size:1.6rem">Forca da senha</span>
                    <div style="display:flex;align-items: center;margin-left:1.5rem">
                        <template v-if="checkPasswordStrength == 'week'">
                            <div style="background:#ff5851;margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;">
                            </div>
                            <div style="margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;background:var(--subbackground);">
                            </div>
                            <div style="margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;background:var(--subbackground);">
                            </div>
                        </template> 
                        <template v-if="checkPasswordStrength == 'medium'">
                            <div style="background:#FF8C05;margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;">
                            </div>
                            <div style="background:#FF8C05;margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;">
                            </div>
                            <div style="margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;background:var(--subbackground);">
                            </div>
                        </template>
                        <template v-if="checkPasswordStrength == 'strong'">
                            <div style="background:#2bdf44;margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;">
                            </div>
                            <div style="background:#2bdf44;margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;">
                            </div>
                            <div style="background:#2bdf44;margin-right:1rem;width:8rem;height:1.5rem;border-radius:2rem;">
                            </div>
                        </template>
                    </div>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;width:100%;position:relative;z-index:1;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z" fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input @focus="toFocusRPwd" @blur="toBlurRPwd" v-model="regRPassword" :type="regRPasswordMode" :placeholder="$t('ConfirmPassword')" @keyup.enter="createAccount"/>
                    </div>
                    <template v-if="regRPasswordMode === 'password'">
                        <svg @click="regRPasswordMode = 'text'" class="icon" style="position:relative;z-index:1;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="regRPasswordMode === 'text'">
                        <svg @click="regRPasswordMode = 'password'" class="icon" style="position:relative;z-index:1;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                        </svg>
                    </template>
                    <div class="InputBoxInner" :style="regRPasswordFocus && showUserRPwdTips ? 'border:0.1rem solid red' : regRPasswordFocus && !showUserRPwdTips ? 'border:0.1rem solid green' : ''">
                    </div>
                </div>
                <div v-if="showUserRPwdTips" style="display:flex;align-items: center;margin-bottom:1.5rem;">
                    <img :src="WarningIcon" style="width:1.3rem;" />
                    <span style="color:#e0153a;font-size:1.3rem;font-weight:bold;margin-left:.5rem;">{{ regRPassword.length > 0 ? regPassword != regRPassword ? $t("PasswordDontMatch") : $t("PasswordInputRegTips") : $t("PasswordRegTips") }}</span>
                </div>

                <div class="optionBox">
                    <div class="optionLeft">
                        <Checkbox :model.sync="eighthChe"></Checkbox>
                        <div class="formDes" style="margin:0;width:100%;flex:1;margin-left:1rem;">
                            By accessing the site.I confirm that I am 18 years old and I have read the
                            <span class="formDesButton" @click="toAgreement">Terms of Service</span>
                        </div>
                    </div>
                </div>
                <div class="LoginButton buttonTransition" @click="createAccount" :style="!regCanPush ? 'background:rgba(255, 255, 255, 0.3)' : ''">
                    {{ $t("CreateAccount") }}
                    <span style="font-size:1rem;margin-left:0.3rem;font-weight:bold;" v-loading="isPush"></span>
                </div>

            </div>
        </template>

        <Modal title="Forget Password" :visible.sync="forgetVisible">
            <div class="forgetBox">
                <div class="InputBox">
                    <div style="display:flex;align-items: center;width:100%">
                        <div class="selectBox">

                            <el-dropdown @visible-change="changePhoneConutry" trigger="click" placement="bottom-start">
                                <div class="selectBox">
                                    <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                        <path d="M512 298.666667m-128 0a128 128 0 1 0 256 0 128 128 0 1 0-256 0Z" fill="#495669"></path>
                                        <path d="M640 426.666667h-17.066667c-29.866667 25.6-68.266667 42.666667-110.933333 42.666666s-81.066667-17.066667-110.933333-42.666666H384c-46.933333 0-68.266667 42.666667-85.333333 85.333333-25.6 68.266667-68.266667 192-42.666667 256 17.066667 42.666667 38.4 85.333333 85.333333 85.333333h341.333334c46.933333 0 68.266667-42.666667 85.333333-85.333333 21.333333-59.733333-17.066667-192-42.666667-256-17.066667-42.666667-38.4-85.333333-85.333333-85.333333z" fill="#495669"></path>
                                    </svg>
                                    <span>+55</span>
                                    <svg style="margin-left:.5rem" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                        <path d="M281.6 409.6l221.866667 268.8c4.266667 4.266667 12.8 4.266667 17.066666 0l221.866667-268.8c4.266667-8.533333 0-17.066667-8.533333-17.066667H290.133333c-12.8 0-17.066667 8.533333-8.533333 17.066667z" fill="#495669"></path>
                                    </svg>
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <div class="countryItem">
                                        <span>Philippines</span>
                                        <span>+55</span>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <input v-model="forgetPhone" type="text" placeholder="Phone number"/>
                    </div>
                    <svg @click="forgetPhone = ''" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
                    </svg>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;width:100%">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z" fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input :type="forgetPwd" placeholder="Password"/>
                    </div>
                    <template v-if="forgetPwd === 'password'">
                        <svg @click="forgetPwd = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z" fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="forgetPwd === 'text'">
                        <svg @click="forgetPwd = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z" fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="verifiCodeBox">
                    <div class="verifiCodeBoxLeft">
                        <div class="verifiCodeItem" @click="inputCode" v-text="codeNum[0]">
                        </div>
                        <div class="verifiCodeItem" @click="inputCode" v-text="codeNum[1]">
                        </div>
                        <div class="verifiCodeItem" @click="inputCode" v-text="codeNum[2]">
                        </div>
                        <div class="verifiCodeItem" @click="inputCode" v-text="codeNum[3]">
                        </div>
                    </div>
                    <div class="verifiCodeBoxRight buttonTransition">
                        Code
                    </div>
                </div>
                <div class="lastOption">
                    <div class="lastOptionLeft" @click="closeForget">
                        <svg style="transform:rotate(180deg)" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z" fill="#8da5ca"></path>
                        </svg>
                    </div>
                    <div class="lastOptionRight buttonTransition" @click="closeForget">
                        Reset password
                    </div>
                </div>
                <input v-model="codeNum" style="height:0;background:none;width:0;border:0;padding:0;margin:0;outline:0;" type="number" onInput="value=value.replace(/[^\d]/g,'')" :maxlength="4" ref="codeInput"/>
            </div>
        </Modal>

        <MonthSelect :visible.sync="monthVisible" :value.sync="month"></MonthSelect>
    </div>
</template>

<script>

import FaceBook from "@/assets/img/facebook.png";
import Modal from "@/components/Modal.vue";
import Checkbox from "@/components/Checkbox.vue";
import SettingInput from "@/components/SettingInput.vue";
import MonthSelect from "@/components/MonthSelect.vue";
import Radio from "@/components/Radio.vue";

import WarningIcon from "@/assets/withdraw/warning_icon.webp";

import { register} from "@/api/user.js";

import { checkAlphanumeric,checkEmail,checkPhoneNumber } from "@/utils/interceptor.js";
import { mapState } from 'vuex';

export default {
    name: "Login",
    data() {
        return {
            WarningIcon,
            FaceBook,
            navSelected: this.$route.query.mode === 'register' ? 1 : 0,
            navList: [this.$t("Login"),this.$t("Register")],
            realCode: false,
            forgetVisible: false,
            rememberMe: true,
            eighthChe: true,

            classItemIndex:1,

            forgetPwd: "password",
            phoneCountry: false,
            codeNum: "",

            loginPasswordMode: "password",
            loginPhone: "",
            loginPhoneFocus:false,
            loginPassword: "",
            loginPasswordFocus:false,

            regPhone: "",
            regPhoneFocus:false,
            regPassword: "",
            regPasswordFocus:false,
            regPasswordMode: "password",
            regRPassword: "",
            regRPasswordFocus:false,
            regRPasswordMode: "password",


            forgetPhone: "",

            verfitycode:"",

            isPush: false,

            monthVisible:false,

            cpfFocus:false,
            realNameFocus:false,

            basicVisible:true,
            gender:"1", // 性别
            idcard:"", // cpf
            year:"", // 生日年
            month:"", // 生日月
            day:"", // 生日日
            realName:"", // 真实姓名
        }
    },
    computed:{
        ...mapState({
            cpfEnable: state => state.user.cpfEnable,
        }),
        checkPasswordStrength() {
            let strength = 0;
            let password = this.regPassword;
            // 检查密码长度
            if (password.length >= 8) {
                strength++;
            }
            // 检查是否包含大写字母
            if (/[A-Z]/.test(password)) {
                strength++;
            }
            // 检查是否包含小写字母
            if (/[a-z]/.test(password)) {
                strength++;
            }
            // 检查是否包含数字
            if (/\d/.test(password)) {
                strength++;
            }
            // 检查是否包含特殊字符
            if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
                strength++;
            }

            if (strength <= 2) {
                return "week"; // Weak
            } else if (strength === 3) {
                return "medium"; // Medium
            } else {
                return "strong"; // Strong
            }
        },
        showUserNameTips(){
            let flag = false;
            const regPhone = /^\d{11}$/; // 正则表达式：11位数字
            const regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 正则表达式：邮箱格式
            const minUsernameLength = 6; // 用户名最小长度
            const maxUsernameLength = 20;

            switch(this.classItemIndex){
                case 1: // 电话号码
                    if (!regPhone.test(this.regPhone)) {
                        flag = true; // 验证通过
                    }
                    break;
                case 2: // 邮箱
                    if (!regEmail.test(this.regPhone)) {
                        flag = true; // 验证通过
                    }
                    break;
                case 0: // 用户名
                    if (!(this.regPhone.length >= minUsernameLength && this.regPhone.length <= maxUsernameLength)) {
                        flag = true; // 验证通过
                    }
                    break;
            }

            return flag && this.regPhoneFocus;
        },
        showUserPwdTips(){
            let flag = false;
            
            if(this.regPassword == this.regPhone){
                flag = true;
            }

            if (this.regPassword.length < 6 || this.regPassword.length > 20) {
                flag = true; // 验证通过
            }

            return flag && this.regPasswordFocus;
        },
        showUserRPwdTips(){
            let flag = false;

            if (this.regRPassword.length < 6 || this.regRPassword.length > 20) {
                flag = true; // 验证通过
            }
            
            if(this.regRPassword != this.regPassword){
                flag = true; // 验证通过
            }

            return flag && this.regRPasswordFocus;
        },
        showCPFTips(){
            let flag = false;

            if (isNaN(this.idcard) || this.idcard == '') {
                flag = true;
            }
            return flag && this.cpfFocus;
        },
        showRealNameTips(){
            let flag = false;

            if (this.realName == '') {
                flag = true;
            }
            return flag && this.realNameFocus;
        },
        showBirthdayTips(){
            const currentYear = new Date().getFullYear();
            const age = currentYear - parseInt(this.year || new Date().getFullYear());
            return age < 18;
        },
        showPwdStrong(){
            let flag = true;

            if (this.regPassword.length < 6 || this.regPassword.length > 20) {
                flag = false; // 验证通过
            }

            return flag;
        },
        showLoginPhoneTips(){
            let flag = false;
            const regPhone = /^\d{11}$/; // 正则表达式：11位数字
            const regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 正则表达式：邮箱格式
            const minUsernameLength = 6; // 用户名最小长度
            const maxUsernameLength = 20;

            switch(this.classItemIndex){
                case 1: // 电话号码
                    if (!regPhone.test(this.loginPhone)) {
                        flag = true; // 验证通过
                    }
                    break;
                case 2: // 邮箱
                    if (!regEmail.test(this.loginPhone)) {
                        flag = true; // 验证通过
                    }
                    break;
                case 0: // 用户名
                    if (!(this.loginPhone.length >= minUsernameLength && this.loginPhone.length <= maxUsernameLength)) {
                        flag = true; // 验证通过
                    }
                    break;
            }
            return flag && this.loginPhoneFocus;
        },
        showLoginPwdTips(){
            let flag = false;
            
            if(this.loginPassword == this.loginPassword){
                flag = true;
            }

            if (this.loginPassword.length < 6 || this.loginPassword.length > 20) {
                flag = true; // 验证通过
            }

            return flag && this.loginPasswordFocus;
        },
        basicCanPush(){
            return this.idcard && this.realName && this.year && this.month && this.day && this.gender && this.year.length == 4 && !this.showCPFTips && !this.showRealNameTips && !this.showBirthdayTips
        },
        regCanPush(){
            const registerParams = {userName: this.regPhone, password: this.regPassword,rpassword:this.regRPassword};

            switch(this.classItemIndex){
                case 0: // 用户名
                    if(!checkAlphanumeric(this.regPhone)){
                        return false;
                    }
                    break;
                case 1:
                    if(!checkPhoneNumber(this.regPhone)){ // 手机号
                        return false;
                    }
                    break;
                case 2:
                    if(!checkEmail(this.regPhone)){ // 邮箱
                        return false;
                    }
                    break;
                default:
                    break;
            }
                    
            if(registerParams.userName==="" || registerParams.password===""){
                this.isPush = false;
                return false;
            }

            if(registerParams.userName == registerParams.password){
                this.isPush = false;
                return false;
            }

            if(registerParams.rpassword != registerParams.password){
                this.isPush = false;
                return false;
            }

            if(!this.eighthChe){
                return false;
            }

            return true;
        },
        logCanPush(){
            switch(this.classItemIndex){
                case 0: // 用户名
                    if(!checkAlphanumeric(this.loginPhone)){
                        return false;
                    }
                    break;
                case 1:
                    if(!checkPhoneNumber(this.loginPhone)){ // 手机号
                        return false;
                    }
                    break;
                case 2:
                    if(!checkEmail(this.loginPhone)){ // 邮箱
                        return false;
                    }
                    break;
                default:
                    break;
            }
            return true;
        }
    },  
    components: {
        Modal,
        Checkbox,
        SettingInput,
        MonthSelect,
        Radio
    },
    methods: {
        
        toBlurLoginPassword(){
            this.loginPasswordFocus = false;
        },
        toFocusLoginPassword(){
            this.loginPasswordFocus = true;
        },

        toBlurLoginPhone(){
            this.loginPhoneFocus = false;
        },
        toFocusLoginPhone(){
            this.loginPhoneFocus = true;
        },

        toAgreement(){
            this.$router.push("/agreement");
        },

        toBlurRealName(){
            this.realNameFocus = false;
        },
        toFocusRealName(){
            this.realNameFocus = true;
        },

        toBlurCPF(){
            this.cpfFocus = false;
        },

        toFocusCPF(){
            this.cpfFocus = true;
        },

        basicRegister(){
            if(this.basicCanPush){
                this.basicVisible = false;
            }
        },
        YearValidateInput(event) {
            let inputValue = event.target.value;
            // 只允许输入数字且限制为4位
            if (!/^\d{0,4}$/.test(inputValue)) {
                inputValue = inputValue.slice(0, 4); // 保留前4位数字
            }
            event.target.value = inputValue;
            this.$emit('input', inputValue); // 更新 v-model
            this.year = inputValue;
        },
        DateValidateInput(event) {
            let inputValue = event.target.value;
            // 只允许输入数字且限制为1到2位
            if (!/^\d{0,2}$/.test(inputValue)) {
                inputValue = inputValue.slice(0, 2); // 保留前2位数字
            }
            let date;
            // 将日期限制在1到31之间
            if(inputValue == ''){
                date = 0;
            }else{
                date = parseInt(inputValue, 10);
            }
            if (date > 31) {
                date = 31;
            } else if (date < 1 && inputValue !== '') {
                date = 1;
            }
            // 补零处理
            event.target.value = date;
            this.$emit('input', date); // 更新 v-model
            this.day = date;
        },
        showMonth(){
            this.monthVisible = true;
        },
        toBlurRPwd(){
            this.regRPasswordFocus = false;
        },
        toFocusRPwd(){
            this.regRPasswordFocus = true;
        },

        toBlurPwd(){
            this.regPasswordFocus = false;
        },

        toFocusPwd(){
            this.regPasswordFocus = true;
        },

        toBlurUserName(){
            this.regPhoneFocus = false;
        },

        toFocusUserName(){
            this.regPhoneFocus = true;
        },

        changeType(type){
            this.navSelected = type;
            this.classItemIndex = 1
        },
        changeClass(number){
            this.classItemIndex = number;
        },
        inputCode() {
            this.$nextTick(() => {
                this.$refs.codeInput.focus();
            });
        },
        parseMonth(month){
            switch(month){
                case this.$t("January"):
                    return "01"
                case this.$t("February"):
                    return "02"
                case this.$t("March"):
                    return "03"
                case this.$t("April"):
                    return "04"
                case this.$t("May"):
                    return "05"
                case this.$t("June"):
                    return "06"
                case this.$t("July"):
                    return "07"
                case this.$t("August"):
                    return "08"
                case this.$t("September"):
                    return "09"
                case this.$t("October"):
                    return "10"
                case this.$t("November"):
                    return "11"
                case this.$t("December"):
                    return "12"
            }
        },
        // 创建账号
        createAccount() {
            if(this.eighthChe){
                if (!this.isPush) {
                    this.isPush = true;
                    const formattedDay = this.day < 10 ? `0${this.day}` : `${this.day}`;
                    const registerParams = {
                        userName: this.regPhone, 
                        password: this.regPassword,
                        idcard:this.idcard,
                        birthday:`${this.year}-${this.parseMonth(this.month)}-${formattedDay}`,
                        sex:this.gender,
                        realName:this.realName
                    };

                    if(!this.checkAccount()) return;
                    
                    if(registerParams.userName===""||registerParams.password===""){
                        this.$message({
                            message: this.$t("PasswordCanNotBeEmpty"),
                        });
                        this.isPush = false;
                        return ;
                    }

                    if(registerParams.userName == registerParams.password){
                        this.$message({
                            message: this.$t("AccountCantSameForPassword"),
                        });
                        this.isPush = false;
                        return ;
                    }


                    register(registerParams.userName,registerParams.password,this.classItemIndex,registerParams.birthday,registerParams.idcard,registerParams.sex,registerParams.realName,this.$store.state.user.agentid).then(res => {
                        if(res.code == 200){
                            // 登录
                            this.$message({
                                message:this.$t("LoginSuccess"),
                            });
                            this.$store
                            .dispatch("Login", {account: this.regPhone, password: this.regPassword,type:this.classItemIndex})
                            .then((res) => {
                                this.$message.success({
                                    message:this.$t("LoginSuccess"),
                                    duration:1000
                                });
                                this.$store.dispatch("setAvatar",Math.floor(Math.random() * (12 - 1 + 1)) + 1)
                                localStorage.removeItem("readPopover");
                                this.$router.push({path: this.redirect || "/home"}).catch(() => {
                                });
                                this.isPush = false;
                            })
                            .catch((e) => {
                                this.isPush = false;
                            });
                        }else{
                            this.$message({
                                message: res.msg,
                            });
                        }
                        this.isPush = false;
                    }).catch();
                }
            }else{
                this.$message({
                    message:this.$t("ConfirmAge"),
                });
            }
        },
        closeForget() {
            this.forgetVisible = false;
        },
        changePhoneConutry(val) {
            this.phoneCountry = val;
        },
        toShowForget() {
            this.forgetVisible = true;
        },
        toBack() {
            this.$router.go(-1)
        },
        // 登录
        async Login() {
            if (!this.isPush) {
                this.isPush = true;

                if(!this.checkAccount()) return;

                this.$store
                    .dispatch("Login", {account: this.loginPhone, password: this.loginPassword,type:this.classItemIndex})
                    .then((res) => {
                        this.$message.success({
                            message:this.$t("LoginSuccess"),
                            duration:1000
                        });
                        this.$store.dispatch("setAvatar",Math.floor(Math.random() * (12 - 1 + 1)) + 1)
                        localStorage.removeItem("readPopover");
                        this.$router.push({path: this.redirect || "/home"}).catch(() => {
                        });
                        this.isPush = false;
                    })
                    .catch((e) => {
                        this.isPush = false;
                    });
            }
        },

        checkAccount(){
            // 检查账号格式
            if(this.navSelected == 0){
                switch(this.classItemIndex){
                    case 0: // 用户名
                        if(!checkAlphanumeric(this.loginPhone)){
                            this.$message(this.$t("UsernameAlphanumeric"));
                            this.isPush = false;
                            return false;
                        }
                        break;
                    case 1:
                        if(!checkPhoneNumber(this.loginPhone)){ // 手机号
                            this.$message(this.$t("PhoneNumberCheck"));
                            this.isPush = false;
                            return false;
                        }
                        break;
                    case 2:
                        if(!checkEmail(this.loginPhone)){ // 邮箱
                            this.$message(this.$t("EmailCheck"));
                            this.isPush = false;
                            return false;
                        }
                        break;
                    default:
                        break;
                }
                return true;
            }else{
                switch(this.classItemIndex){
                    case 0: // 用户名
                        if(!checkAlphanumeric(this.regPhone)){
                            this.$message(this.$t("UsernameAlphanumeric"));
                            this.isPush = false;
                            return false;
                        }
                        break;
                    case 1:
                        if(!checkPhoneNumber(this.regPhone)){ // 手机号
                            this.$message(this.$t("PhoneNumberCheck"));
                            this.isPush = false;
                            return false;
                        }
                        break;
                    case 2:
                        if(!checkEmail(this.regPhone)){ // 邮箱
                            this.$message(this.$t("EmailCheck"));
                            this.isPush = false;
                            return false;
                        }
                        break;
                    default:
                        break;
                }
                return true;
            }
        }
    },
    mounted(){
    },
}
</script>

<style>
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition-delay: 99999s;
        -webkit-transition: color 99999s ease-out,
        background-color 99999s ease-out;
    }
</style>

<style scoped>

.verifyButton{
    background:var(--gdient2);
    color:var(--basic);
    font-weight:bold;
    font-size:1.3rem;
    height:80%;
    box-sizing:border-box;
    padding:0 1rem;
    border-radius:1rem;
    display:flex;
    justify-content:center;
    align-items:center;
}

.rightSvg{
    width:0.8rem;
    transform:rotate(90deg)
}

.rightSvg path{
    fill:var(--subfont);
}

.FlexBox{
    display:flex;
    align-items:center;
    margin-bottom:1.5rem;
}

.fixedFormItemRequired{
    font-size:1rem;
    color:red;
    font-weight:bold;
    margin-left:0.5rem;
}

.fixedFormItemLabel{
    font-size:1.5rem;
    font-weight:bold;
    color:var(--mainfont);
    margin-bottom:0.5rem;
    display:flex;
}

.fixedFormItem{
    display:flex;
    flex-direction:column;
}

.fixedForm{
    position:absolute;
    z-index:100;
    top:7rem;
    width:100%;
    height:100%;
    background:var(--background);
    box-sizing:border-box;
    padding:0 2rem;
    padding-top:1.5rem;
}

.countryItem > span:nth-child(1) {
    color: var(--subfont);
    font-weight: bold;
    font-size: 1.6rem;
}

.countryItem > span:nth-child(2) {
    color: #fff;
    font-weight: bold;
    font-size: 1.6rem;
}

.countryItem {
    height: 5rem;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1.5rem;
}

.lastOptionLeft > svg {
    width: 2rem;
    height: 2rem;
}

.lastOptionLeft {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background: var(--sub16black);
}

.getVerfityCode{
    background:var(--gdient2);
    border-radius:4rem;
    height:100%;
    color:#fff;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:1.5rem;
    box-sizing:border-box;
    padding:0 2rem;
}

.formClassList{
    display:flex;
    height:6rem;
    width:100%;
    background:#000;
    margin-top:1.5rem;
    margin-bottom:1.5rem;
    background:var(--subbackground);
    border-radius:4rem;
    box-sizing:border-box;
    padding:0.4rem;
}
.formClassItem{
    width:33.33%;
    font-size:1.5rem;
    height:100%;
    border-radius:4rem;
    display:flex;
    align-items: center;
    justify-content: center;
    color:var(--subfont);
    font-weight:bold;
    cursor:pointer;
}
.formClassItem.active{
    background:var(--gdient2) !important;
    color:#fff !important;
}

.lastOption {
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.lastOptionRight {
    height: 5rem;
    width: 100%;
    flex: 1;
    background: var(--gdient2);
    color: #fff;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    font-size: 1.7rem;
    font-weight: bold;
}

.verifiCodeBoxRight {
    height: 4rem;
    width: 8rem;
    background: var(--gdient2);
    color: #fff;
    margin-left: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    font-size: 1.5rem;
}

.verifiCodeItem {
    width: 5.5rem;
    height: 5.5rem;
    background-color: var(--subbackground);
    border-radius: 1rem;
    color: var(--sub17black);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
}

.verifiCodeBoxLeft {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-between;
}

.verifiCodeBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forgetBox {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.otherButton > img {
    height: 5rem;
}

.otherButton {
    height: 3rem;
    margin-top: 1rem;
    text-align: center;
}

.otherTitle {
    color: var(--mainfont);
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1.5rem;
}

.formDesButton {
    color: var(--gdient2);
    text-decoration: underline;
}

.formDes {
    color: var(--subfont);
    margin-top: 2rem;
    font-size: 1.3rem;
    font-weight: bold;

}

.SignUpButton {
    color: var(--gdient2);
    box-sizing: border-box;
    border: 1px solid var(--gdient2);
    border-radius: 1rem;
    height: 5.5rem;
    font-weight: bold;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.LoginButton {
    border-radius: 1rem;
    background: var(--gdient2);
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.7rem;
    margin-top: 2rem;
}

.optionBox {
    color: var(--gdient2);
    font-size: 1.5rem;
}

.optionBox > span {
    text-decoration: underline;
}

.optionLeft span {
    margin-left: 1rem;
}

.optionLeft {
    display: flex;
    align-items: center;
}

.radio {
    height: 3rem;
    width: 3rem;
    background: var(--subbackground);
    border-radius: .5rem;
}

.optionBox {
    display: flex;
    justify-content: space-between;
}

.InputBox input::placeholder { 
    font-weight: bold;
    color:var(--subfont);
}

.InputBox input {
    color: #fff;
    font-size: 1.5rem;
    background: none;
    outline: none;
    border: none;
    margin-left: 1rem;
    width:100%;
    font-weight:bold;
}

.InputBox > svg {
    height: 2rem;
    width: 2rem;
}

.InputBox path {
    fill:var(--subfont);
}

.selectBox svg {
    height: 3rem;
    width: 2.5rem;
}

.selectBox span {
    color: var(--subfont);
    margin-left: .5rem;
    font-size: 1.5rem;
    font-weight:bold;
}

.selectBox {
    display: flex;
    align-items: center;
}

.selectBox path {
    fill:var(--subfont);
}

.form {
    box-sizing: border-box;
    padding: 0 3rem;
}

.InputBoxInner{
    width:100%;
    height:100%;
    position:absolute;
    z-index:0;
    left:0;
    top:0;
    border-radius: 1rem;
}

.InputBox {
    height: 5.5rem;
    background: var(--subbackground);
    width: 100%;
    border-radius: 1rem;
    display: flex;
    box-sizing: border-box;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    position:relative;
}

.formTitle {
    height: 10rem;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 2.3rem;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 2rem;
}

.navItem.active {
    border-top: 1px solid var(--subbackgroundspecial);
    border-left: 1px solid var(--subbackgroundspecial);
    border-right: 1px solid var(--subbackgroundspecial);
    border-bottom: 1px solid var(--primary);
    background:var(--primary);
    height:calc(100% + 1px);
    position: relative;
    z-index: 5;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    color:var(--basic);
}

.navItem {
    /* box-sizing: border-box; */
    height: 5rem;
    width: 12rem;
    justify-content: center;
    color: var(--subfont);
    font-size: 1.7rem;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.header svg {
    height: 2rem;
    width: 2rem;
}

.header path {
    fill:var(--subfont);
}

.header {
    height: 7rem;
    width: 100%;
    border-bottom: 1px solid var(--subbackgroundspecial);
    box-sizing: border-box;
    padding: 0 3rem;
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navList {
    display: flex;
    height: 100%;
}

.box {
    width: 100%;
    height: 100vh;
    background: var(--primary);
}
</style>

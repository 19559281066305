<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="vipBox" v-if="vipList.length != 0">
                <div class="vipItem">
                    <div class="vipLevel" :style="'background-image:url(' + vipList[current].levelBg + ')'">
                        <!-- <img v-preload="vipList[current].levelBg" /> -->
                        <div class="vipTitle">
                            <span class="vipLevelText" v-text="vipList[current].levelName"></span>
                            <template v-if="user">
                                <template v-if="vipList[current].level == user.level">
                                    <span class="isCurrent">{{ $t("CurrentLevel") }}</span>
                                </template>
                            </template>
                            <img :src="vipList[current].levelIcon" />
                        </div>
                        <div class="vipProcess" style="position:relative;z-index:1;">
                            <span :style="'color::#6c3c02'">{{ $t("Recharge") }}<span :style="'color::#6c3c02;margin-left:.5rem'">0/{{ vipList[current].payment }}</span></span>
                            <div class="vipProcessText">
                            </div>
                        </div>
                        <div class="vipProcess" style="margin-top:2rem;position:relative;z-index:1;">
                            <span>{{ $t("BetAmount") }}<span :style="'margin-left:.5rem'">0/{{ vipList[current].gameStatement }}</span></span>
                            <div class="vipSubProcessText">
                            </div>
                            <div class="vipProcessDes">
                                <span>V{{ vipList[current].level }}</span>
                                <span>V{{ vipList[current+1] ? vipList[current+1].level : vipList[current].level }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="vipContent">
                        <div class="vipListBox">
                            <div class="previous-list" @click="scrollLeft">
                                <div class="list-button">
                                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M765.952 124.928l0 81.92 0 129.024 0 156.672 0 159.744q0 76.8 0.512 141.312t0.512 100.352q0 24.576-11.776 40.96t-29.696 22.016-39.424 0-39.936-24.064q-37.888-38.912-81.92-81.408t-90.624-85.504-94.208-86.016-91.648-82.944q-19.456-17.408-29.184-43.008t-8.704-53.248 11.776-54.272 32.256-45.056q39.936-34.816 79.36-70.144t80.896-72.704 87.04-78.336 96.768-87.04q22.528-20.48 45.568-27.136t41.472-2.048 29.696 20.48 11.264 40.448z" fill="#ffffff"></path></svg>
                                </div>
                            </div>
                            <div class="vipList" ref="vipList">
                                <VIPItem
                                :style="index == 0 ? 'transform-origin: left center;' : index == vipList.length -1 ? 'transform-origin: right center;' : ''"
                                 @click="changeCurrent" v-for="(item,index) in vipList" :key="index" :index="index" :current="current" :name="item.levelName" :icon="item.levelIcon" :level="item.level" :user="user">
                                </VIPItem>
                            </div>
                            <div class="next-list" @click="scrollRight">
                                <div class="list-button">
                                    <svg style="transform:rotate(180deg)" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M765.952 124.928l0 81.92 0 129.024 0 156.672 0 159.744q0 76.8 0.512 141.312t0.512 100.352q0 24.576-11.776 40.96t-29.696 22.016-39.424 0-39.936-24.064q-37.888-38.912-81.92-81.408t-90.624-85.504-94.208-86.016-91.648-82.944q-19.456-17.408-29.184-43.008t-8.704-53.248 11.776-54.272 32.256-45.056q39.936-34.816 79.36-70.144t80.896-72.704 87.04-78.336 96.768-87.04q22.528-20.48 45.568-27.136t41.472-2.048 29.696 20.48 11.264 40.448z" fill="#ffffff"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div class="vipPowerArray" v-html="vipList[current].levelDescription">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex';
    import VIPItem from "@/components/VIPItem.vue";

    export default {
        name:"VIP",
        data(){
            return {
                current:0,
                isDragging: false
            }
        },
        components:{
            VIPItem
        },
        computed:{
            ...mapState({
                user: state => state.user.user || { level:-1 },
                vipList: state => state.user.vipList,
            }),
        },
        methods:{
            scrollLeft() {
                let container = this.$refs.vipList;
                const scrollAmount = 200; // 滚动的像素数
                // 计算新的滚动位置
                const newScrollLeft = Math.max(container.scrollLeft - scrollAmount, 0);
                // 平滑滚动到新的位置
                container.scrollTo({
                    left: newScrollLeft,
                    behavior: 'smooth'
                });
            },
            scrollRight() {
                let container = this.$refs.vipList;
                const scrollAmount = 200; // 滚动的像素数
                // 计算新的滚动位置
                const maxScrollLeft = container.scrollWidth - container.clientWidth;
                const newScrollLeft = Math.min(container.scrollLeft + scrollAmount, maxScrollLeft);
                // 平滑滚动到新的位置
                container.scrollTo({
                    left: newScrollLeft,
                    behavior: 'smooth'
                });
            },
            initSelect() {
                // 找到当前用户等级对应的索引
                this.current = this.vipList.findIndex(item => item.level == this.user.level);

                this.$nextTick(() => { // 在视图更新后执行滚动操作
                    // 获取具有 .vipListItem 和 .active 类的元素
                    const targetElement = document.querySelector('.vipListItem.active');
                    // 获取父容器（即具有横向滚动条的容器）
                    const parentElement = this.$refs.vipList;

                    if (parentElement && targetElement) {
                        // 设置横向滚动条位置，滚动到目标元素的左侧位置
                        parentElement.scrollLeft = targetElement.offsetLeft;
                    }
                });
            },
            changeCurrent(index){
                this.current = index;
            }
        },
        mounted(){
            this.initSelect();
        }
    }

</script>

<style scoped>

    .list-button{
        height:2rem;
        width:2rem;
        background:var(--primary);
        border-radius:50%;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .list-button > svg {
        width:1.5rem;
        height:1.5rem;
    }
    .list-button path {
        fill:var(--subfont);
    }

    .previous-list,
    .next-list {
        cursor: pointer;
    }

    .next-list{
        width:2rem;
        height:10rem;
        position:absolute;
        right:0;
        top:1.5rem;
        background:#000;
        z-index:10;
        background: linear-gradient(to right, transparent, var(--gdient1));
        opacity: 0.5;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:1rem;
    }
    .previous-list{
        width:2rem;
        height:10rem;
        position:absolute;
        left:0;
        top:1.5rem;
        background:#000;
        z-index:10;
        background: linear-gradient(to left, transparent, var(--gdient1));
        opacity: 0.5;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:1rem;
    }

    .vipContent{
        display:flex;
        flex-direction: column;
    }

    .vipList{
        position: relative;
        height:100%;
        width: 100%;
        white-space: nowrap;
        overflow-y:hidden;
        overflow-x: auto;
        box-sizing:border-box;
        padding-bottom:0.5rem;
        padding-top:1.5rem;
    }

    .vipListBox{
        height:fit-content;
        width:100%;
        overflow:hidden;
        position: relative;
    }

    .vipList::-webkit-scrollbar { /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
        border-radius:0.5rem;
        overflow:hidden;
    }

    .vipList::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
        background: var(--gdient2);
        opacity:0.5;
        border-radius:0.5rem;
        overflow:hidden;
    }

    .vipList::-webkit-scrollbar-track { /*滚动条里面外层轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
        border-radius: 0;
        background: var(--primary);
        opacity:0.2;
        border-radius:0.5rem;
        overflow:hidden;
    }

    .vipPowerArrayDes>div>span:nth-child(2){
        margin-top:.3rem;
    }
    .vipPowerArrayDes>div{
        margin-bottom:3rem;
        color:#fff;
        font-size:1.4rem;
        display:flex;
        flex-direction: column;
        font-weight:bold;
    }
    .vipPowerArrayReal>span>span{
        color:#fff;
    }
    .vipPowerArrayReal>span{
        color:var(--mainfont);
        font-size:1.4rem;
        font-weight:bold;
        margin-bottom:.5rem;
    }
    .vipPowerArrayReal{
        display:flex;
        flex-direction: column;
        margin-top:2rem;
        align-items: center;
    }
    .vipPowerArrayTitle{
        color:var(--blue3);
        font-weight:bold;
        font-size:1.8rem;
        margin-top:2rem;
    }
    .vipPowerArray>img{
        width:10rem;
    }
    .vipPowerArray{
        background:var(--primary);
        box-sizing:border-box;
        padding-top:2rem;
        padding-bottom:3rem;
        border-radius:1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-top:1.5rem;
        font-size:1.5rem;
        width:100%;
        flex:1;
        min-height:30rem;
    }
    .viewPowerDays{
        height:3rem;
        width:3rem;
        background-color:rgba(255,255,255,.3);
        border-radius:.5rem;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        color:#fff;
        font-weight:bold;
        margin-top:1rem;
    }
    .vipPowerLast{
        margin-top:auto;
        font-size:1.4rem;
        font-weight:bold;
        margin-bottom:.5rem;
    }
    .vipPowerItem>svg{
        margin-top:auto;
        height:3rem;
        width:3rem;
    }
    .vipPowerItem>span:nth-child(3){
        font-size:1.8rem;
        font-weight:bold;
        text-align: center;
        margin-top:.7rem;
    }
    .vipPowerItem>img{
        opacity: .3;
        height:3rem;
        margin-top:1rem;
    }
    .vipPowerItem>span:nth-child(1){
        font-size:1.5rem;
        font-weight:bold;
        text-align: center;
    }
    .vipPowerItem{
        width:30%;
        height:17rem;
        background-repeat:no-repeat;
        background-size:100% 100%;
        box-sizing:border-box;
        padding:1rem 1rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        padding-bottom:.5rem;
    }
    .vipPowerList{
        width:100%;
        display:flex;
        margin-top:2rem;
        justify-content: space-between;
    }
    .vipPowerTitle{
        margin-top:2rem;
        font-size:1.7rem;
        font-weight:bold;
        text-align: center;
    }
    .vipDesign{
        width:100%;
        margin-top:2rem;
    }
    .vipProcessDes{
        width:100%;
        justify-content: space-between;
        display: flex;
        margin-top:.3rem;
        font-size:1.2rem;
        font-weight:bold;
    }
    .vipProcessText{
        background:rgba(0,0,0,0.3);
        height:1.5rem;
        width:55%;
        border-radius:1rem;
        margin-top:.5rem;
    }
    .vipSubProcessText{
        background:rgba(0,0,0,0.3);
        height:1rem;
        width:100%;
        border-radius:1rem;
        margin-top:.5rem;
    }

    .vipProcess>span{
        font-size:1.4rem;
        font-weight:bold;
    }
    .vipProcess{
        display:flex;
        flex-direction: column;
    }
    .isCurrent{
        font-size:1.4rem;
        font-weight:bold;
    }
    .vipLevelText{
        font-size:3.5rem;
        font-weight:bold;
    }
    .vipTitle>img{
        height:10rem;
    }
    .vipTitle{
        display:flex;
        align-items: center;
        justify-content: space-between;
        position:relative;
        z-index:1;
    }
    .vipLevel > img {
        height:100%;
        width:100%;
        position:absolute;
        z-index:0;
        left:0;
        top:0;
    }
    .vipLevel{
        height:25rem;
        width:100%;
        display:flex;
        background-size:100% 100%;
        background-repeat:no-repeat;
        flex-direction: column;
        box-sizing:border-box;
        padding:2rem 2.5rem;
        position:relative;
        color: white; /* 默认字体颜色 */
    }
    .vipItem{
        height:100%;
        width:100%;
        /* position:absolute; */
        transition:all .5s;
    }
    .vipBox{
        /* height:130rem; */
        height:fit-content;
        position:relative;
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:1rem 3rem;
        background:var(--background);
        overflow:hidden;
    }
    .box {
        background: var(--background);
        box-sizing: border-box;
        box-sizing: border-box;
        padding: 1rem 0;
        padding-bottom: 12rem;
    }
</style>
<template>
    <div class="cashwheelPopup">
        <div class="cashwheelBox" :id="'cashwheelBox' + id">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in source" :key="item.id" :dataRoute="item.routerUrl || ''">
                    <img :src="item.fullImgUrl" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    
    import Swiper, {Autoplay, Pagination, Navigation} from 'swiper';
    import 'swiper/swiper-bundle.css';
    Swiper.use([Autoplay, Pagination, Navigation])

    import EventBus from "@/main";

    export default {
        name:"SwiperImg",
        props:{
            source:{
                type:Array,
                default:[],
            },
            id:{
                type:Number,
            }
        },  
        data(){
            return {

            }
        },
        methods:{
            toRoute(url){
                if(url){
                    console.log("你好",url)
                    if(url.indexOf('https') != -1){
                        window.open(url, '_blank');
                    }else{
                        this.$router.push(url);
                    }
                }
            },
            initSwiper(){
                if((!this.swiper) && this.source.length > 0){
                    this.$nextTick(() => {
                        console.log("测试",'#cashwheelBox'+this.id)
                        this.swiper = new Swiper('#cashwheelBox'+this.id, {
                            loop: this.source.length > 1, // 是否循环播放
                            autoplay: this.source.length > 1 ? { disableOnInteraction:false,delay: 3000 } : false,
                            speed: 1000,
                            on: {
                                click: (swiper, event) => {
                                    if(this.swiper.clickedSlide){
                                        console.log("你好")
                                        this.toRoute(this.swiper.clickedSlide.attributes.dataRoute.value);
                                    }
                                },
                            },
                        });
                    })
                }
            }
        },
        mounted(){
            EventBus.$on("updateBanner",() => {
                this.initSwiper();
            })
        },
    }

</script>

<style lang="css" scoped>
    .cashwheelSlide {
        width: 100%;
        height: 100%;
    }

    .swiper-slide img {
        width: 85%;
        height: 85%;
    }

    .cashwheelBox {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .cashwheelPopup > img {
        height:100%;
        width:100%;
    }
    
    .cashwheelPopup{
        width: 8rem;
        height: 8rem;
    }
</style>
<template>
    <div :class="$store.state.user.appEnable ? 'viewBoxGlobalSpecial' : 'viewBoxGlobal'">
        <div class="box">
            <div class="helpImg">
                <img :src="activity.fullNoticeImg" v-if="!loading"/>
                <div class="skeleton" v-else style="height:26rem">
                </div>
            </div>
            <div class="helpP">
                <!-- <div class="helpTitle">
                    <span v-if="!loading">
                        {{activity.noticeTitle}}
                    </span>
                    <div class="skeleton" v-else style="height:2rem;">
                    </div>
                </div> -->
                <div class="helpDes">
                    <div v-if="!loading" v-html="activity.noticeContent">
                    </div>
                    <div class="skeleton" v-else style="height:40rem;">
                    </div>
                </div>
            </div>
        </div>
        <div class="optionButton buttonTransition" @click="toDeposit">
            {{ $t("ClaimNow") }}
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Help from "../assets/img/bg.png";
import Footer from "../components/Footer.vue"
import {getNoticeById} from "@/api/home"

export default {
    name: "Help",
    data() {
        return {
            activity:{},
            Help,
            loading:true,
        }
    },
    watch: {
        '$route'(to, from) {
            if (to.query.id !== from.query.id) {
                // 执行你的逻辑，如重新获取数据或刷新视图
                this.getInfo();
            }
        }
    },
    created() {
        this.getInfo();
    },
    methods: {
        getInfo(){
            this.loading = true;
            getNoticeById(this.$route.query.id).then(res => {
                this.activity = res.data
                this.loading = false;
            });
        },
        toDeposit() {
            if(this.activity.noticeType == 0){
                this.$router.push({
                    path: "/cashwheel",
                })
            }else{
                this.$router.push({
                    path: "/deposit",
                    query: {
                        mode: "deposit"
                    }
                })
            }
        },
    },
    components: {
        Footer
    }
}
</script>

<style scoped>
.optionButton {
    background-color: var(--error);
    width: 80%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    border-radius: 1rem;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.helpDes {
    font-size: 1.5rem;
    font-weight: bold;
    overflow-wrap: break-word; /* 文字超出容器宽度时换行 */
    word-wrap: break-word; /* 兼容旧版浏览器 */
    margin: 0;
    margin-top: .5rem;
    border-radius:2rem;
    overflow:hidden;

}

.helpTitle {
    font-size: 2rem;
    color: var(--green10);
    font-weight: bold;
    overflow-wrap: break-word; /* 文字超出容器宽度时换行 */
    word-wrap: break-word; /* 兼容旧版浏览器 */
    margin:2rem 0;
    border-radius:2rem;
    overflow:hidden;
}

.helpP {
    margin-bottom: 4rem;
}

.helpImg img {
    width: 100%;
}

.helpImg {
    width: 100%;
    margin-bottom: 1rem;
    height:fit-content;
    border-radius:2rem;
    overflow:hidden;
}

.box {
    background: var(--background);
    box-sizing: border-box;
    height: fit-content;
    min-height: 90vh;
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding: 2rem 2rem;
}
</style>

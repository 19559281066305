<template>
    <div class="bannerBox">
        <div class="banner-container" v-if="!loading">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in swiperList" :key="item.id" :dataRoute="item.routerUrl">
                    <img :src="item.fullImgUrl" v-show="!loading"/>
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div class="skeleton" v-else>
        </div>
    </div>
</template>

<script>

import Banner from "@/assets/img/banner.png"

import Swiper, {Autoplay, Pagination, Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
import Skeleton from './Skeleton.vue';
import { mapState } from 'vuex';

import EventBus from "@/main";

Swiper.use([Autoplay, Pagination, Navigation])

export default {
    components: {Skeleton},
    name: "Banner",
    data() {
        return {
            Banner,
            loading:false,
        }
    },
    computed:{
        ...mapState({
            stationimglist: state => state.user.stationimglist,
        }),
        swiperList(){
            return this.stationimglist.filter(a => a.imgType === "1");
        },
    },
    methods: {
        toRoute(url){
            if(url.indexOf('https') != -1){
                window.open(url, '_blank');
            }else{
                this.$router.push(url);
            }
        },
        changeSwiperList(){
            if((!this.swiper) && this.swiperList.length > 0){
                this.$nextTick(() => {
                    this.swiper = new Swiper('.banner-container', {
                        loop: this.swiperList.length > 1, // 是否循环播放
                        autoplay: this.swiperList.length > 1 ? { disableOnInteraction:false,delay: 3000 } : false,
                        pagination: {
                            el: ".swiper-pagination",
                            clickable: true
                        },
                        speed: 1000,
                        on: {
                            click: (swiper, event) => {
                                if(this.swiper.clickedSlide){
                                    this.toRoute(this.swiper.clickedSlide.attributes.dataRoute.value);
                                }
                            },
                        },
                    });
                })
            }
        },
    },
    mounted(){
        EventBus.$on("updateBanner",() => {
            console.log("你好")
            this.changeSwiperList();
        })
    },
}
</script>

<style scoped>

.bannerBox{
    width: 100%;
    height: 26rem;
    overflow:hidden;
    border-radius: 2rem;
}

.swiper-slide {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100% !important; /* 设置轮播项高度 */
}

.swiper-slide img {
    width: 100%;
    height: 100%;
}

.banner-container {
    width: 100%;
    height: 26rem;
    overflow: hidden;
    border-radius: 2rem;
    position: relative;
}

.notificationRadio {
    height: 1rem;
    width: 1rem;
    background-color: var(--success);
    box-shadow: 0px 0px 10px var(--success);
    border-radius: 50%;
}

.notificationDesign {
    z-index: 11;
    position: absolute;
    left: 0;
    top: 0;
    height: 3rem;
    width: 3rem;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-bar {
    width: 100%; /* 设置通知栏宽度 */
    overflow: hidden; /* 隐藏溢出内容 */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
    height: 3rem;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
}

.notification {
    white-space: nowrap; /* 禁止换行 */
    width: calc(100% - 3rem);
    margin-left: 3rem;
    overflow: hidden;
}

.notification-item {
    color: #fff;
    display: inline-block; /* 内联布局 */
    padding-right: 20px; /* 右边距，调整公告之间的间隔 */
    animation: slide 10s linear infinite; /* 设置动画效果 */
}

@keyframes slide {
    0% {
        transform: translateX(100%); /* 从右侧移出 */
    }
    100% {
        transform: translateX(-100%); /* 移动到左侧 */
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 10s; /* 设置过渡效果 */
}
</style>

<template>
    <div class="profileProcess">
        <div class="profileProcessLeft">
            <span>{{ $t("TaskWithdrawal") }}</span>
            <div class="profileProcessAccount" @click="toWithdrawal">
                <img :src="Wallet" />
                <span class="walletBalance">R$&nbsp;{{ taskInfo.isWithdrawal ? taskInfo.balance : 0 }}</span>
                <span class="walletDes">
                    {{ $t("WithdrawableAmount") }}
                </span>
            </div>
        </div>
        <div class="profileProcessRight">
            <div class="rightFirst">
                <span>{{ taskInfo.sumCompletedAmount }}/{{ taskInfo.sumTargetTurnover }}</span>
                <svg
                    v-if="uniqueSupplier"
                    @click="showBet"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="200"
                    height="200"
                >
                <path
                    d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m32 704h-64v-64h64v64z m-64-128V256h64v384h-64z"
                    fill="#647183"
                ></path>
                </svg>
            </div>
            <div class="rightSecond">
              <div class="rightSecondProcess">
                <div class="rightSecondRealProcess" :style="'width:' + (taskInfo.sumTargetTurnover !== 0 ? taskInfo.sumCompletedAmount / taskInfo.sumTargetTurnover * 100 : 0) + '%'">
                </div>
              </div>
              <span>{{ ((taskInfo.sumTargetTurnover !== 0 ? taskInfo.sumCompletedAmount / taskInfo.sumTargetTurnover * 100 : 0)).toFixed(2) }}%</span>
            </div>
            <div class="rightThird">
              {{ $t("TaskPlayTips") }}
            </div>
            <div class="rightFourth">
              <span class="amountDes">{{ $t("AmountUnlock") }}</span>
              <span class="amountValue">R${{ taskInfo.isWithdrawal ? 0 : taskInfo.balance }}</span>
            </div>
        </div>

        <Modal :visible.sync="betVisible" :gradient="true" :padding="false">
            <div class="bonusBox">
                <div class="bonusTitle">
                    <span></span>
                    <span>{{ $t("BetCoefficient") }}</span>
                    <svg
                        @click="betVisible = false"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="200"
                        height="200"
                    >
                        <path
                        d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z"
                        fill="#5e6f8f"
                        ></path>
                    </svg>
                </div>
                <div class="bonusText" v-html="uniqueSupplierContent">
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>

    
    import Wallet from "@/assets/img/wallet.png";

    import Modal from "@/components/Modal.vue";

    import { WithdrawalInfo } from "@/api/deposit.js";
    import { Lucky } from "@/api/cashwheel.js";

    export default {
        name:'Withdrawal',
        components:{
            Modal
        },
        data(){
            return {
                Wallet,
                betVisible:false,
                taskInfo:{
                    sumCompletedAmount:0,
                    sumTargetTurnover:0
                },
                uniqueSupplier:0,
                uniqueSupplierContent:""
            }
        },
        methods:{
            toWithdrawal() { // 跳转到提现页
                this.$router.push({
                    path: "/deposit",
                });
            },
            showBet() { // 显示弹窗
                this.betVisible = true;
            },
            getTaskInfo(){
                WithdrawalInfo().then(res => {
                    if(res.code == 200){
                        this.taskInfo = res.data;
                    }
                })
                Lucky().then(res => {
                    if(res.code == 200){
                        this.uniqueSupplier = res.data.uniqueSupplier;
                        this.uniqueSupplierContent = res.data.uniqueSupplierContent;
                    }
                })
            }
        },
        mounted(){
            this.getTaskInfo();
        }
    }
</script>

<style scoped>
    .bonusText {
        margin: 0 auto;
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .bonusTitle > svg {
        width: 2rem;
        height: 2rem;
    }

    .bonusTitle path {
        fill:var(--icon);
    }

    .bonusTitle > span {
        color: var(--basic);
        font-size: 2rem;
        font-weight: bold;
    }
    .bonusTitle {
        display: flex;
        justify-content: space-between;
    }
    .bonusBox {
        box-sizing: border-box;
        padding: 3rem 2rem;
    }

    .amountValue {
        color: #fff;
        font-size: 1.5rem;
    }
    .amountDes {
        color: var(--subfont);
        font-size: 1.5rem;
    }

    .rightFourth > span {
        text-align: center;
    }

    .rightFourth {
        display: flex;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 0.5rem 1.5rem;
        border-radius: 1rem;
        align-items: center;
        margin-top: auto;
    }
    .rightThird {
        word-break: break-all;
        color: #fff;
        margin-top: 1.5rem;
        font-size: 1.5rem;
        width: 90%;
    }
    .rightSecondRealProcess{
        height:100%;
        border-radius: 2rem;
        background:var(--icon);
    }
    .rightSecondProcess {
        width: 100%;
        background: var(--primary);
        border-radius: 2rem;
        height: 100%;
        overflow:hidden;
    }
    .rightSecond > span {
        color: var(--mainfont);
        font-size: 1.3rem;
    }
    .rightSecond {
        height: 1rem;
        width: 100%;
        margin-top: 1rem;
        display: flex;
        align-items: center;
    }
    .rightFirst > span {
        color: var(--success);
        font-size: 1.5rem;
    }

    .rightFirst path {
        fill:var(--icon);
    }

    .rightFirst > svg {
        height: 2rem;
        width: 2rem;
    }
    .rightFirst {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
    .profileProcessRight {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        margin-left: 1.5rem;
    }
    .walletDes {
        color: #fff;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 500;
    }
    .walletBalance {
        color: var(--success);
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 1rem;
    }
    .profileProcessAccount img {
        width: 4rem;
    }
    .profileProcessAccount {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        padding: 1.5rem 2rem;
        border-radius: 1rem;
        width: 15rem;
        margin-top: 1rem;
    }
    .profileProcessLeft > span {
        color: #fff;
        font-size: 2rem;
        font-weight: 500;
    }
    .profileProcessLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profileProcess span {
        font-weight:bold;   
    }
    .profileProcess {
        display: flex;
        height: fit-content;
        width: 100%;
        margin-top: 1rem;
        border-radius: 1rem;
        background: radial-gradient(circle at 25% 10%, var(--modalgdient), var(--modalgdient2));
        padding: 2rem 1rem 1.5rem 2rem;
        box-sizing: border-box;
    }
</style>
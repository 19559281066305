<template>
    <transition name="breathe">
        <div v-show="visible" class="payModal">
            <div class="payModalText">
                <div class="payModalHeader">
                    <span>{{ $t("DepositInformation") }}</span>
                    <svg @click="closeModal" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                        <path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path>
                    </svg>
                </div>
                <template v-if="type != 'http'">
                    <div class="payModalBody" v-if="!isFinish">
                        <div class="payModalMoney">
                            <img :src="channelImg" />
                            <span>R${{ depositForm.rechargeAmount }}</span>
                        </div>
                        <div class="payDescription">
                            {{ $t("PayModalTip1") }}
                        </div>
                        <div class="payqrcode" id="payqrcode">
                            <div ref="qrcodediv" style="width:100%;height:100%">
                                
                            </div>
                        </div>
                        <div class="payModalMoney" style="margin-top:1.5rem;" v-if="isCountdown">
                            <span style="font-size:1.8rem">{{ timeFormat }}</span>
                        </div>
                        <div class="payButton" @click="toCopy">
                            {{ $t("CopyPIXCode") }}
                        </div>
                        <span class="payTips">
                            {{ $t("PayModalTip2") }}
                        </span>
                    </div>
                    <div class="payModalBody" v-else>
                        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M438.564 681.495a32 32 0 0 1-24.621-11.558L289.851 520.479c-11.29-13.597-9.418-33.771 4.178-45.062 13.597-11.29 33.772-9.418 45.062 4.178l101.405 122.133L685.232 351.26c12.353-12.641 32.611-12.874 45.252-0.523 12.641 12.351 12.875 32.611 0.523 45.252L461.45 671.859a32 32 0 0 1-22.886 9.636z" p-id="4230" fill="#ffffff"></path><path d="M511.295 957.3c-60.354 0-118.903-11.862-174.021-35.258-16.268-6.904-23.858-25.69-16.954-41.959 6.906-16.269 25.692-23.858 41.959-16.953 47.166 20.02 97.301 30.17 149.015 30.17 210.492 0 381.74-171.247 381.74-381.739s-171.248-381.74-381.74-381.74-381.739 171.248-381.739 381.74c0 36.946 5.261 73.459 15.637 108.521 5.015 16.946-4.657 34.75-21.604 39.765-16.946 5.015-34.75-4.656-39.765-21.604-12.122-40.962-18.268-83.584-18.268-126.683 0-60.16 11.79-118.538 35.042-173.512 22.452-53.083 54.587-100.748 95.512-141.674 40.926-40.926 88.592-73.061 141.674-95.513 54.974-23.252 113.351-35.042 173.512-35.042s118.539 11.79 173.512 35.042c53.082 22.452 100.748 54.587 141.674 95.513s73.061 88.591 95.512 141.674c23.252 54.974 35.042 113.352 35.042 173.512s-11.79 118.538-35.042 173.512c-22.451 53.082-54.587 100.748-95.512 141.674-40.926 40.926-88.592 73.061-141.674 95.513C629.834 945.51 571.456 957.3 511.295 957.3z" fill="#ffffff">
                            </path>
                        </svg>
                        <span style="font-size: 2rem;color: #fff;margin-top: 2rem;">{{ $t("RechargeSuccessful") }}</span>
                        <div class="payButton" @click="gotoHome">
                            {{ $t("ReturnToHomePage") }}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <iframe :src="depositResult.content" class="payIframe" height="800" @load="loadSuccess"></iframe>
                </template>
            </div>
        </div>
    </transition>
</template>

<script>

    import QRCode from 'qrcodejs2'
    import { PaymentStatus } from "@/api/deposit";

    export default {
        name:"PayModal",
        data(){
            return {
                countDownInterval:null,
                checkDepositResult:null,
                timeFormat:"00:00:00",
                isCountdown:false,
                isFinish:false, // 是否支付成功
            }
        },
        props:{
            visible:{
                type:Boolean
            },
            depositForm:{
                type:Object
            },
            depositResult:{
                type:Object
            },
            endTime:{
                type:Number
            },
            type:{
                type:String
            },
            channelImg:{
                type:String
            }
        },
        methods:{
            loadSuccess(){
                this.$emit("showDeposit");
            },  
            closeModal(){
                this.$emit("update:visible",false)
            },
            gotoHome(){
                this.$router.push("/home")
            },
            generateQRCode() {
                this.$nextTick(() => {
                    const qrcodediv = this.$refs.qrcodediv;
                    const parentWidth = qrcodediv.clientWidth;
                    const parentHeight = qrcodediv.clientHeight;
                    const size = Math.min(parentWidth, parentHeight);
                    // 清空之前的二维码内容
                    qrcodediv.innerHTML = '';
                    new QRCode(qrcodediv, {
                        text: this.depositResult.content,
                        width: size,
                        height: size,
                        correctLevel: 3, // <== 关键
                        typeNumber:40,
                    });
                })
            },
            countDownFunc(){
                let currentTime = this.endTime - new Date().getTime()
                if(currentTime <= 0){
                    this.isCountdown = false;
                    clearInterval(this.countDownInterval);
                    clearInterval(this.checkDepositResult);
                    this.closeModal();
                }else{
                    let currentDate = new Date(currentTime);
                    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
                    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
                    this.timeFormat = `00:${minutes}:${seconds}`;
                }
            },
            checkDeposit(){
                let data = {
                    outTradeNo:this.depositResult.outTradeNo
                }
                PaymentStatus(data).then(res => {
                    if(res.data.status){ // 支付成功
                        this.isFinish = true;
                        clearInterval(this.countDownInterval);
                        clearInterval(this.checkDepositResult);
                        this.$store.dispatch('GetInfo');
                        this.$store.dispatch("clearDepositInfo");
                    }
                })
            },
            toCopy(){
                this.$message.info(this.$t("CopySuccess"));
                // 创建一个隐藏的textarea元素
                const textarea = document.createElement('textarea');
                // 确保textarea不可见
                textarea.style.position = 'fixed';
                textarea.style.left = '-9999px';
                // 设置文本内容
                textarea.value = this.depositResult.content;
                // 将textarea添加到文档中
                document.body.appendChild(textarea);
                // 选择文本
                textarea.select();
                // 执行复制命令
                document.execCommand('copy');
                // 移除textarea元素
                document.body.removeChild(textarea);
            }
        },
        watch:{
            endTime(val){
                if(val > 0){
                    this.isCountdown = true;
                    this.countDownFunc();
                    this.countDownInterval = setInterval(this.countDownFunc,1000);
                    this.checkDepositResult = setInterval(this.checkDeposit,2000);
                }
            },
            visible(val){
                if(val){
                    if(this.type != 'http'){
                        this.generateQRCode();
                    }
                }else{
                    clearInterval(this.countDownInterval)
                    clearInterval(this.checkDepositResult)
                }
            }
        },
        beforeDestroy(){
            clearInterval(this.countDownInterval)
            clearInterval(this.checkDepositResult)
        }
    }
</script>

<style scoped>

    .payIframe{
        width:100%;
        height:69rem;
        border:none;
        scrollbar-width:none;
    }

    .breathe-enter-active, .breathe-leave-active {
        animation: breatheAnimation 0.2s ease-in-out;
    }

    @keyframes breatheAnimation {
        0% {
            transform: scale(0.8);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    .payqrcode{
        width:15rem;
        height:15rem;
        margin-top:4rem;
        background:#fff;
        box-sizing:border-box;
        padding:1rem;
    }

    .payTips{
        color:var(--subfont);
        font-size:1.4rem;
        margin-top:2rem;
        word-break: break-all;
        margin-bottom:4rem;
        font-weight:bold;
        text-align: center
    }

    .payButton{
        height:4.5rem;
        width:100%;
        border-radius:1rem;
        background:var(--success);
        display:flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        font-size:1.5rem;
        font-weight:bold;
        margin-top:3rem;
        cursor:pointer;
    }

    .payModalBody > img{
        width:13rem;
        margin-top:3rem;
    }

    .payDescription{
        background:var(--subbackground);
        border-radius:1rem;
        margin-top:1.7rem;
        color:var(--subfont);
        font-weight:bold;
        font-size:1.2rem;
        text-align: center;
        box-sizing:border-box;
        padding:1rem 1rem;
        word-break: break-all;
    }

    .payModalMoney > img {
        width:4rem;
        height:4rem;
        margin-right:1rem;
    }

    .payModalMoney > span {
        color:var(--basic);
        font-size:2rem;
        font-weight:bold;
    }

    .payModalMoney{
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .payModalBody{
        box-sizing:border-box;
        padding:4rem 3rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .payModal{
        width:100%;
        height:fit-content;
        display:flex;
        justify-content: center;
        align-items: center;
        position:relative;
    }
    .payModalText{
        height:fit-content;
        width:38rem;
        border-radius:2rem;
        overflow:hidden;
        position:relative;
        background:var(--primary);
    }
    .payModalHeader{
        height:5rem;
        width:100%;
        box-sizing:border-box;
        border-bottom:0.01rem solid var(--subbackgroundspecial);
        display:flex;
        align-items: center;
        justify-content: center;
        position:relative;
        color:#fff;
        font-size:1.5rem;
        font-weight:bold;
    }
    .payModalHeader > svg {
        position:absolute;
        top:50%;
        right:1rem;
        height:2rem;
        widtH:2rem;
        margin-top:-1rem;
        cursor:pointer;
    }
</style>